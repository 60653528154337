.Semester-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid #ecf5fe;
  text-align: left;
}

.Semester-heading {
  font-size: 17px;
  line-height: 1.3;
  padding: 10px;
  font-weight: 400;
  background-color: #ecf5fe;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Semester-heading strong {
  font-weight: 600;
}

.Semester-container h3 {
  margin-bottom: 0;
}

.Semester-header,
.Semester-body,
.Semester-footer{
  width: 100%;
  padding: 0 1rem;
}

.Semester-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 1rem;

  button {
    text-transform: none !important;
    padding: 0;
    font-size: 17px;
  }

  .Semester-btn-addCourse {
    padding: 0;
    font-weight: bold;
    color: #f9931f !important;
  }
}

.Semester-btn-remove {
  min-width: 40px !important;
  padding: 0 !important;
  margin: 0 auto 0 0 !important;
  color: #f9931f !important;
  opacity: 1 !important;
  font-size: 20px !important;
  height: auto !important;
}
