.result-table {
  width: 100%;
  font-size: 1rem;
  color: #757575;
  text-align: left;
  border-spacing: 0;
  margin-top: 30px;
  /*box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12), 0 3px 1px -2px rgba(0, 0, 0, .2);*/
  max-width: 480px;
  margin: 4rem auto;
  background-color: #f9fafa;
  border: 1px solid #dfdfdf;
}
.result-table thead tr th,
.result-table tbody tr:hover td {
  background-color: #f9f9f9;
  transition: background-color .35s cubic-bezier(.4,0,.2,1);
}

.result-table tbody tr:last-child td {
  background-color: #eee;
}

.result-table th,
.result-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid rgba(0,0,0,.12);
  background-color: #fff;
}